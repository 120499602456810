@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: MontserratBold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

h1,
h2,
h3,
h4 {
  font-family: "MontserratBold", sans-serif;
  margin: 0.3rem 0;
}

p,
span {
  font-family: "Montserrat", sans-serif;
}

html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100vh;
  background-color: #ececec;
}

/* MENU STYLES */

#menu {
  background-color: #d6de21;
  margin-bottom: 3%;
}

#menu ul {
  margin-top: 0;
  width: 100%;
  padding-left: 0;
}

#menu li {
  display: inline-block;
  margin-left: 2%;
  margin-right: 2%;
  font-size: 20px;
  vertical-align: middle;
}

#menu li a {
  color: #000000;
  text-decoration: none;
  vertical-align: middle;
  font-family: "MontserratBold", sans-serif;
}

#menu li a:hover {
  text-decoration: underline;
}

#secondMenuHp {
  text-align: center;
  padding-bottom: 3%;
}
#secondMenuHp > div:nth-child(5) {
  display: none;
  margin-right: 0;
}

.logoMenu {
  width: 5%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #ffffff;
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 1%;
}

.banner {
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
}

#discounts {
  position: relative;
  height: 600px;
}

#discountRoute {
  position: absolute;
  top: 20px;
  left: -40px;
  width: 400px;
}

#discountBus {
  position: absolute;
  top: 60px;
  left: 15px;
  width: 90px;
}

.bodyInnerHP {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.blockMenuItem {
  width: 23.5%;
  display: inline-block;
  background-color: #ffffff;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  padding-bottom: 3%;
}

.blockMenuItem:hover {
  background-color: #d6de21;
  color: black;
}

#secondMenuHp > div:nth-child(1) {
  margin-right: 1%;
}

#secondMenuHp > div:nth-child(2) {
  margin-right: 1%;
  margin-left: 1%;
}

#secondMenuHp > div:nth-child(3) {
  margin-right: 1%;
  margin-left: 1%;
}

#secondMenuHp > div:nth-child(4) {
  margin-left: 1%;
}

.blockMenuItem a {
  font-family: "MontserratBold", sans-serif;
  color: #000000;
  text-decoration: none;
}

.blockMenuItem a:hover {
  color: #000000;
}

/* FOOTER STYLES */
.footer {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}

.footerCont {
  background-color: #676767;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer p {
  display: inline-block;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.footer p:not(:last-child) {
  margin-right: 3%;
}

.footer p:last-child {
  width: 130px;
}

.footer svg {
  margin-right: 5%;
}

.iconBlock {
  height: 3rem;
  font-size: 3rem;
  padding-top: 5%;
  padding-bottom: 3%;
  vertical-align: 0;
}

/* COMPANY PAGE STYLE */

.leftSide {
  display: inline-block;
  width: 60%;
  vertical-align: top;
}

.rightSide {
  display: inline-block;
  width: 40%;
  vertical-align: top;
  margin-top: 7%;
}

.myButton {
  display: block;
  margin-left: auto;
  background-color: #808080;
  width: 240px;
  border: 1px solid #ffffff;
  border-radius: 7px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 3%;
  font-family: "Montserrat", sans-serif;
}

.myButton:hover {
  border: 1px solid #ffffff;
  background-color: #404040;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.myButtonTables {
}

.leftSide p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

.podpis {
  float: right;
  width: 25%;
}

/* TIME TABLES PAGE STYLE */
.map {
  width: 100%;
}

.myButtonTables {
  display: block;
  margin-left: auto;
  background-color: #808080;
  width: 280px;
  border: 1px solid #ffffff;
  border-radius: 7px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 3%;
  font-family: "Montserrat", sans-serif;
}

.myButtonTables:hover {
  border: 1px solid #ffffff;
  background-color: #404040;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

/* RESERVATION PAGE STYLE */

.MuiFormLabel-root.Mui-focused {
}

.subTitle {
  display: inline-block;
  vertical-align: top;
  margin-left: 5%;
  font-size: 15px;
}

.myButtonTicket {
  background-color: #808080;
  width: 220px;
  border: 1px solid #ffffff;
  border-radius: 7px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 3%;
  font-family: "Montserrat", sans-serif;
  float: right;
  margin-top: 2%;
}

.myButtonTicket:hover {
  border: 1px solid #ffffff;
  background-color: #404040;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.titleReservation {
  display: inline-block;
}

.myButtonReservation {
  font-size: 20px;
  background-color: #808080;
  border: 1px solid #ffffff;
  border-radius: 7px;
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 3%;
  font-family: "MontserratBold", sans-serif;
  display: inline-block;
}

.myButtonReservation:hover {
  border: 1px solid #ffffff;
  background-color: #404040;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.contIndicators {
  display: inline-block;
  float: right;
}

.squareAvailable {
  margin-right: 10%;
  vertical-align: middle;
  display: inline-block;
  background-color: #99ccff;
  width: 20px;
  height: 20px;
  border: 1px solid #99ccff;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.squareBlocked {
  margin-right: 10%;
  vertical-align: middle;
  display: inline-block;
  background-color: #aa0000;
  width: 20px;
  height: 20px;
  border: 1px solid #aa0000;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.squareSelected {
  margin-right: 10%;
  vertical-align: middle;
  display: inline-block;
  background-color: #d6de21;
  width: 20px;
  height: 20px;
  border: 1px solid #d6de21;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
}

.colorAndText {
  margin-bottom: 7%;
  width: 120px;
  font-family: "Montserrat", sans-serif;
}

.formCont {
  display: inline-block;
  width: 35%;
}

.contImgReservation {
  width: 65%;
  display: inline-block;
  margin-left: 5%;
}

.mista {
  display: block;
  width: 100%;
}

.formCont label {
  display: block;
  font-family: "MontserratBold", sans-serif;
  margin-bottom: 2%;
}

.formCont label input {
  font-family: "Montserrat", sans-serif;
  display: block;
  background-color: transparent;
  border-bottom: 2px solid #ffffff;
  border-top: none;
  border-left: none;
  width: 100%;
}

.formInline {
  display: inline-block !important;
  width: 45%;
}

.formInlineRight {
  margin-left: 10%;
}

.formCont label select {
  font-family: "Montserrat", sans-serif;
  display: block;
  background-color: transparent;
  border-bottom: 2px solid #ffffff;
  border-top: none;
  border-left: none;
}

.secondRow {
  margin-top: 2%;
  margin-bottom: 2%;
}

/* CONTACT PAGE STYLE */
.blockContactItem {
  width: 23.5%;
  display: inline-block;
  background-color: #ffffff;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  padding-top: 3%;
  padding-bottom: 3%;
  text-align: center;
}

.blockContactItem h2 {
  font-family: "MontserratBold", sans-serif;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.blockContactItem span {
  font-family: "Montserrat", sans-serif;
  display: block;
  margin-bottom: 5%;
  margin-top: 5%;
}

.bodyInnerContact {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.bodyContContact {
  background-color: #ececec;
  padding-bottom: 3%;
}

.bodyContContact > div > div:nth-child(2) {
  margin-right: 1%;
}

.bodyContContact > div > div:nth-child(3) {
  margin-right: 1%;
  margin-left: 1%;
}

.bodyContContact > div > div:nth-child(4) {
  margin-right: 1%;
  margin-left: 1%;
}

.bodyContContact > div > div:nth-child(5) {
  margin-left: 1%;
}

/* ADS PAGE STYLE */
.busImg {
  width: 100%;
}

/* FORM STEP ONE STYLE */

.react-autosuggest__container {
  width: 100%;
}

.textFieldFull {
  width: 100%;
}

.textFieldWithSwitch {
  width: 80%;
}

#formFirstStep {
  display: block;
  position: relative;
}

#switchButton {
  display: block;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 1.5rem;
  margin: 0;
  padding: 0;
}

.textFieldHalf {
  width: 45%;
}

.textFieldMargin {
  margin-right: 10% !important;
}

.MuiFormControl-root {
  margin-top: 3% !important;
}

/* FORM SELECT SEAT STYLE */
.leftTarifCont {
  display: inline-block;
  vertical-align: top;
  width: 30%;
}

.selectTarif {
  width: 60%;
}

.progressBar {
  margin-top: 2%;
}

.formSeatTariff {
  display: block;
  width: 30%;
  vertical-align: top;
}

/* LOGIN FORM STYLE */
.loginInput {
  display: block !important;
}

#loginForm {
  width: 35%;
}

#loginForm .MuiInput-root {
  width: 100%;
}

.thirdRow {
  margin-top: 5%;
  margin-bottom: 5%;
}

.MuiButton-containedSecondary {
  margin-right: 2% !important;
}

.btnRegister {
  text-decoration: none;
  font-size: 17px;
  font-family: "MontserratBold", sans-serif;
  color: #3f51b5;
}

.btnRegister:hover {
  text-decoration: underline;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.29);
}

.myButtonFinish {
  text-decoration: none;
  color: #ffffff;
  background-color: #3f51b5;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  margin-right: 1rem;
}

.myButtonFinish:hover {
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

/* SELECT SEAT STYLE */
.container {
  width: 100%;
  margin: auto;
}

.grid {
  background-color: #ffffff;
  padding: 2%;
}

.grid tr {
  overflow-x: visible;
}

.grid td {
  padding: 5px;
  width: 20px;
  border-radius: 5px;
  text-align: center;
  float: left;
  margin: 5px;
  font-family: "MontserratBold", sans-serif;
}

.empty {
  background-color: transparent;
  border: 1px solid transparent;
  color: transparent;
}

.grid td.available:hover,
.grid td.reserved:hover {
  opacity: 0.7;
  cursor: pointer;
}

.reserved {
  background-color: #d6de21 !important;
  border: 1px solid #d6de21 !important;
  border-radius: 5px;
  color: #000000;
  display: inline-block;
}

.blocked {
  background-color: #aa0000 !important;
  border: 1px solid #aa0000 !important;
  border-radius: 5px;
  color: white;
  display: inline-block;
}

.reserved::after {
  content: "";
}

.available::after {
  content: "";
}

.available {
  background-color: #99ccff;
  border: 1px solid #99ccff;
  color: #fff;
}

ul.container {
  padding: 0;
  margin: 0;
}

.container li {
  font-family: "Montserrat", sans-serif;
  list-style: none;
  display: block;
  margin: 20px 0;
  padding: 0;
}

.container li .seat {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  border: 1px solid #000000;
  width: 20px;
  padding: 5px;
  border-radius: 3px;
  list-style: none;
  display: inline-block;
  margin-top: 0px;
}
.container li .tariffSelect {
  display: inline-block;
  width: 150px;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  vertical-align: top;
}

.container li .tariffSelect div {
  margin: 0;
  padding: 5px;
  width: 140px;
}

.container li .price {
  display: inline-block;
  margin-left: 10px;
}

.summaryTicket {
  display: block;
  border-radius: 3px;
  border: #000 1px solid;
  padding: 3px;
  margin: 2px;
  max-width: 300px;
}

.left h4 {
  margin-bottom: 1%;
  margin-top: 1%;
}

.right {
  padding-left: 2%;
}

.right h4 {
  margin-bottom: 1%;
}
.right ul {
  margin-top: 1%;
}

.priceCont h4 {
  padding-left: 2%;
}

.react-autosuggest__container {
  position: relative;
  display: inline-block;
  margin-top: -10px;
  /* z-index: 99999; */
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 16px;
  padding: 10px;
  font-weight: 300;
  font-size: 13px;
  border: 1px solid rgba(0, 126, 255, 0.24);
  border-radius: 4px;
  color: #000;
  background-color: #fff;
}

.react-autosuggest__input::placeholder {
  color: #777;
  opacity: 1;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  position: absolute;
  padding: 0;
  background-color: #fff;
  list-style-type: none;
  width: 100%;
  border: 1px solid #eee;
}

.react-autosuggest__suggestion {
  color: #000000;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow-x: hidden;
}

.react-autosuggest__suggestions-container {
  position: relative;
  z-index: 999999;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
}

.shortCode {
  background-color: transparent;
  color: #000000;
  padding: 4px;
  border-radius: 2px;
}

.highlight {
  background-color: #3f51b5 !important;
  padding: 4px;
  border-radius: 2px;
  color: #ffffff !important;
}

.connList {
  list-style-type: none;
  padding-left: 0;
}

.connList > li {
  list-style-type: none;
  border-radius: 5px;
  margin-bottom: 1%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
}

.connList > li span {
  width: 20%;
  text-align: center;
  font-weight: bold;
}

.connList > li:hover {
  cursor: pointer;
  background-color: rgba(214, 222, 33, 0.7);
}

.subConnList li {
  display: inline-block;
}

.listHead {
  background-color: #b6bce2;
  padding: 2% 1%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.listHead span {
  font-family: "MontserratBold", sans-serif;
  font-size: 19px;
  width: 20%;
  text-align: center;
}

.subTitleConnList {
  display: inline-block;
  margin-left: 5px;
}

.brqjsF,
.dVFrjD {
  display: none;
}

.btnRegister {
  position: relative;
  top: 10px;
}

.summaryTable {
  padding: 2%;
  background-color: #ffffff;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.noConn {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.noConn svg {
  font-size: 20px;
  margin-right: 10px;
}

/* RESPONSIVE STYLES */

@media only screen and (max-width: 1100px) {
  .blockContactItem {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .blockMenuItem {
    width: 49%;
  }
  #secondMenuHp > div:nth-child(1) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 2%;
    width: 100%;
  }

  #secondMenuHp > div:nth-child(1) .iconBlock {
    width: 30%;
    padding-top: 5%;
    padding-bottom: 2%;
  }

  #secondMenuHp > div:nth-child(2) {
    margin-right: 1%;
    margin-left: 0;
  }
  #secondMenuHp > div:nth-child(3) {
    margin-right: 0;
    margin-left: 1%;
  }
  #secondMenuHp > div:nth-child(3) {
    margin-right: 0;
    margin-left: 1%;
  }

  #secondMenuHp > div:nth-child(4) {
    margin-right: 1%;
    margin-left: 0;
    margin-top: 2%;
  }

  #secondMenuHp > div:nth-child(5) {
    display: inline-block;
    margin-right: 0;
    margin-left: 1%;
    margin-top: 2%;
  }

  #menu li {
    display: none;
  }

  #menu ul {
    text-align: center;
    margin-bottom: 0;
  }

  .logoMenu {
    display: inline-block !important;
    width: 10%;
  }

  .brqjsF span,
  .dVFrjD span {
    background: #000000 !important;
  }

  .brqjsF,
  .dVFrjD {
    display: inline-block;
    top: 20px !important;
    left: 20px !important;
  }

  #main-menu {
    z-index: 9;
    list-style: none;
    background-color: #ffffff;
    -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.16);
  }

  #main-menu li a {
    color: #000000;
  }

  #main-menu li {
    margin-bottom: 3%;
  }

  .logoMenuMobile {
    width: 30%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer {
    text-align: center;
  }

  .footer p {
    font-size: 10pt;
    margin: 0;
  }
}

@media only screen and (max-width: 900px) {
  #menu {
    padding-top: 20px;
  }

  #loginForm {
    width: 82%;
  }

  .formCont {
    display: inline-block;
    width: 100%;
  }

  .connList > li span {
    font-size: 13px;
  }

  .listHead span {
    font-size: 16px;
  }

  .grid td {
    padding: 3px;
    width: 20px;
    margin: 3px;
  }
}

@media only screen and (max-width: 750px) {
  .connList > li span {
    font-size: 11px;
  }

  .listHead span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .formSeatTariff {
    width: 50%;
  }

  .footer {
    width: 90%;
  }
  /* 
  .bodyInnerHP {
    width: 90%;
  } */

  .logoMenu {
    width: 15%;
  }

  .connList > li span {
    font-size: 9px;
    padding: 1%;
  }

  .grid {
    padding: 0;
  }

  .grid td {
    padding: 1px;
    width: 20px;
    margin: 1px;
  }
}

@media only screen and (max-width: 500px) {
  .bodyInnerHP {
    width: 90%;
    padding-bottom: 30%;
  }

  .formSeatTariff {
    width: 80%;
  }

  .connList > li {
    padding: 1%;
  }

  .logoMenu {
    width: 25%;
  }

  .connList > li span {
    font-size: 9px;
    padding: 2%;
  }

  .listHead span {
    font-size: 11px;
  }
}

@media only screen and (max-width: 450px) {
  #loginForm {
    width: 100%;
  }

  .logoMenu {
    width: 25%;
  }

  /* .blockMenuItem {
    width: 100%;
  } */
}

@media only screen and (max-width: 400px) {
  .contIndicators {
    display: none;
  }

  .connList > li span {
    display: table;
  }

  .subTitleConnList {
    font-size: 14px;
  }

  .grid td {
    padding: 0;
    width: 18px;
    margin: 1px;
  }
}

@media only screen and (max-width: 350px) {
}
